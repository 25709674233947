"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findIn, findAllIn, on, closest, trigger} from "@elements/dom-utils";
import * as formValidation from './formValidation';

const selectors = {
    result: '.js-file-upload-form__file-result',
    input: '.js-file-upload-form__input',
    form: '.js-file-upload-form',
    removeItem: '.js-file-upload-form__remove-item',
    formItem: '.js-file-upload-form__item',
    errorMessageMaxFiles: '.js-file-upload-form__error-max-files',
    errorMessageMaxFileSize: ".js-file-upload-form__error-max-file-size",
    errorMessageDescriptionNeeded: ".js-file-upload-form__error-description-needed",
    inputWrapper: ".js-file-upload-form__input-wrapper",
    description: ".js-file-upload-form__description"
};

let fileList = [];

export function init() {

    onFind(selectors.form, function (form) {
        let formInput = findAllIn(selectors.input, form);

        formInput.map(function(input) {
            on('change', function (evt) {
                let inputWrapper = closest(selectors.inputWrapper, evt.target);
                let result = findIn(selectors.result, inputWrapper);
                updateFileUpload(evt.target, result, form, inputWrapper);
            }, input);
        });

        on('submit', function (evt) {
            evt.preventDefault();

            formInput.map(function(input) {
                input.value = "";
            })

            let params = new FormData(form);
            fileList.map(function (fileListElement) {
                fileListElement.files.map(function(item) {
                    params.append(fileListElement.id, item);
                })
            });

            let formAction = form.getAttribute('data-action') || form.getAttribute('action');
            let request = fetch(formAction, {
                method: 'POST',
                redirect: 'follow',
                body: params,
                headers: {}
            });
            request.then(function(response) {
                if(response.url){
                    window.location = response.url;
                }
            })
            .then(function(result) {
                console.log('success');
            })
        }, form);
    });
}

function getFileData(input) {
    return input.files;
}

function removeItemFunc(item, inputName) {

    let itemToRemove = closest(selectors.formItem, item);
    // the reference to this file is saved in data-file
    let itemToRemoveRef = itemToRemove.getAttribute('data-file-name');
    // check reference if this is the right file to be removed
    
    let tempArray = getCurrentFileListObj(inputName).files.filter(obj => obj.name !== itemToRemoveRef);
    fileList = fileList.map(x => (x.id === inputName ? { ...x, files: tempArray} : x));
    

    // fileList = fileList.filter(file => file.name !== itemToRemoveRef);
    closest(selectors.formItem, item).remove();
    // return newFileList;
}

function renderFileItem(name, addDescription, addDescriptionPaceholder, addDescriptionRequired, inputName) {
    return `<div class="mb-3 js-file-upload-form__item" data-file-name="${name}">
                <div class="form-control file-upload__item d-flex justify-content-between">
                    <div>
                       ${name}
                    </div>
                    <div>
                        <button type="button" class="js-file-upload-form__remove-item btn-no-styling fz12">
                            <span class="icon icon-recycle-bin" aria-label="remove item" title="remove item"></span>
                        </button>
                    </div>
                </div>
                `+(addDescription === 'yes' ? `<div class="mt-2">
                    <input name="description_${inputName.replace('[]', '')}[${name}]" class="form-control js-file-upload-form__description" placeholder="${addDescriptionPaceholder} ${name}" ${addDescriptionRequired === 'yes' ? 'required' : ''} />
                </div>` : ``)+`
            </div>`;
}

export function updateFileUpload(input, fileResult, form, inputWrapper) {
    let successState;
    let newItem;
    let files = getFileData(input);
    let maxFileNumber = input.getAttribute('data-pristine-max-files');
    let maxFileSize = input.getAttribute('data-pristine-max-file-size');
    let errorToggleMaxFiles = findIn(selectors.errorMessageMaxFiles, inputWrapper);
    let errorToggleMaxFileSize = findIn(selectors.errorMessageMaxFileSize, inputWrapper);
    let errorToggleDescriptionNeeded = findIn(selectors.errorMessageDescriptionNeeded, inputWrapper);
    let inputName = input.getAttribute("name");


    let currentFileList = fileList.filter(obj => obj.id === inputName);
    let currentFileListLength = currentFileList[0] ? currentFileList[0]?.files?.length : 0;
    currentFileListLength = currentFileListLength ? currentFileListLength : 0;

    displayErrorMessage(errorToggleMaxFiles, errorToggleMaxFileSize, maxFileNumber, maxFileSize, files, currentFileListLength);
    if((files.length + currentFileListLength) <= maxFileNumber && checkFileSize(files, maxFileSize)) {
        Array.from(files).forEach(file => {
            successState = true;
            newItem = renderFileItem(file.name, input.getAttribute('data-add-description'), input.getAttribute('data-add-description-placeholder'), input.getAttribute('data-add-description-required'), input.getAttribute('name'));
            let markupElement = document.createElement('div');
            markupElement.innerHTML = newItem;
            let markupElementNode = markupElement.childNodes[0];
            fileResult.appendChild(markupElementNode);

            if(getCurrentFileListObj(inputName)) {
                fileList.map(x => (x.id === inputName ? { ...x, files: x.files.push(file)} : x));
            } else {
                fileList.push({id:inputName, files: [file]});
            }
            let description = findIn(selectors.description, inputWrapper);
            if(description.getAttribute('required') !== null){
                on('change', (e) => {
                    if(e.target.value === null || e.target.value === undefined || e.target.value.length === 0){
                        errorToggleDescriptionNeeded.removeAttribute('hidden');
                    }else{
                        if(errorToggleDescriptionNeeded.getAttribute('hidden') === null){
                            errorToggleDescriptionNeeded.setAttribute('hidden', '');
                        }
                    }
                }, description);
                trigger('change', description);
            }
        });
    } else {
        successState = false;
    }

    let removeItem = findAllIn(selectors.removeItem, fileResult);
    removeItem.map(function (item){
        on('click', function(evt) {
            evt.preventDefault();
            evt.stopImmediatePropagation();
            removeItemFunc(evt.target, inputName);
            displayErrorMessage(errorToggleMaxFiles, errorToggleMaxFileSize, maxFileNumber, maxFileSize, 0, currentFileListLength - 1);
        }, item);
    });

    if (form.pristine){
        form.pristine.destroy();
        formValidation.init();
    }

    return successState;
}

/*_______________ check file size ______________ */
function checkFileSize(files, maxFileSize) {
    let result = true;
    let i = 0;
    for(i; i < files.length; i++) {
        if (files[i].size > maxFileSize) {
            result = false;
            break;
        }
    }
    return result;
}


function displayErrorMessage(errorToggleMaxFiles, errorToggleMaxFileSize, maxFileNumber, maxFileSize, files, currentFileListLength) {
    /*_______________ hide error message ______________ */
    errorToggleMaxFiles.setAttribute("hidden", "");
    errorToggleMaxFileSize.setAttribute("hidden", "");

    /*_______________ display error message ______________ */
    if((files.length + currentFileListLength) > maxFileNumber) {
        errorToggleMaxFiles.removeAttribute("hidden");
    } else if(!checkFileSize(files, maxFileSize)) {
        errorToggleMaxFileSize.removeAttribute("hidden");
    }
}

function getCurrentFileListObj(id) {
    let currentFile = fileList.filter(file => file.id === id);
    return currentFile.length > 0 ? currentFile[0] : false;
}