"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {addClass, removeClass, findIn} from "@elements/dom-utils";
import Pristine from "pristinejs";
import * as formValidation from "./formValidation";

export function init() {
    onFind('.js-step-form', function (form) {
        let stepsIndex = 1;
        let firstStep = findIn('.js-step-form__step.is-active', form);
        validateStep(firstStep, stepsIndex, form);
    });
}

function validateStep(step, stepsIndex, form) {
    let nextButton = findIn('.js-step-form__next', step);
    let prevButton = findIn('.js-step-form__prev', step);
    let firstLegendItem = form.querySelector(`[data-legend-item-index='1']`);
    addClass('is-current', firstLegendItem);
    if (nextButton) {
        nextButton.addEventListener('click', function () {
            let pristine = new Pristine(step, {});
            pristine.validate();
            if (pristine.validate()) {
                navigateToNextStep(step, stepsIndex, form);
            }
            window.scrollTo({
                top: 640,
                left: 0,
                behavior: 'smooth'
            });
        });
    } if (prevButton) {
        prevButton.addEventListener('click', function () {
            navigateToPrevStep(step, stepsIndex, form);
            window.scrollTo({
                top: 640,
                left: 0,
                behavior: 'smooth'
            });
        });
    }
}

function navigateToNextStep(currentStep, stepsIndex, form) {
    stepsIndex++;
    let nextStep = form.querySelector(`[data-step-index='${stepsIndex}']`);
    let currentLegendItem = form.querySelector(`[data-legend-item-index='${stepsIndex-1}']`);
    let nextLegendItem = form.querySelector(`[data-legend-item-index='${stepsIndex}']`);
    removeClass('is-active', currentStep);
    removeClass('is-current', currentLegendItem);
    addClass('is-checked', currentLegendItem);
    addClass('is-active', nextStep);
    addClass('is-current', nextLegendItem);
    validateStep(nextStep, stepsIndex, form);
}

function navigateToPrevStep(currentStep, stepsIndex, form) {
    stepsIndex--;
    let prevStep = form.querySelector(`[data-step-index='${stepsIndex}']`);
    let currentLegendItem = form.querySelector(`[data-legend-item-index='${stepsIndex+1}']`);
    let prevLegendItem = form.querySelector(`[data-legend-item-index='${stepsIndex}']`);
    removeClass('is-active', currentStep);
    removeClass('is-current', currentLegendItem);
    removeClass('is-checked', currentLegendItem);
    removeClass('is-checked', prevLegendItem);
    addClass('is-active', prevStep);
    addClass('is-current', prevLegendItem);
    addClass('is-active', prevLegendItem);
    validateStep(prevStep, stepsIndex, form);
}