import './scripts/common';
import './scripts/common-nav';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};


/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import {onFind} from "@elements/init-modules-in-scope";
import {on} from "@elements/dom-utils";

import * as regionSlider from './scripts/regionSlider';
regionSlider.init();

import Tab from 'bootstrap.native/dist/components/tab-native.esm';
onFind('[data-toggle="tab"]',function (element) {
    new Tab(element);
});

import * as ajaxForm from './scripts/ajax-form';
ajaxForm.init();

import * as ajaxFormMap from './scripts/ajax-form-map';
ajaxFormMap.init();

import * as wishlist from './scripts/wishlist';
wishlist.init();

import * as gallery from './scripts/gallery';
gallery.init();

import * as lazyIframe from '@elements/lazy-iframe';
lazyIframe.init();

import * as conditionalForm from './scripts/conditionalForm';
conditionalForm.init();

import * as stepForm from './scripts/step-form';
stepForm.init();

import {translate} from '@elements/translations';
import * as poolEnquiry from '@elements/pool-enquiry';

poolEnquiry.init({
    renderFunctions: {
        "inputs": {
            'item':(data) => {
                let markupElementInput = document.createElement('div');
                markupElementInput.innerHTML = `<input class="" name="pool-enquiries[]" id="" value="${data.id}" hidden>`;

                return markupElementInput.childNodes[0];
            }
        },
        "result": {
            'item': (data, onRemove, baseElement) => {
                let markupElementItem = document.createElement('div');
                let markupElementRemoveButton = document.createElement('div');

                markupElementItem.innerHTML = `<div class="fz14 mb-2 mr-3 text-white">${data.title}</div>`;
                markupElementRemoveButton.innerHTML = '<button class="btn-no-styling ml-2"><span class="icon icon-close" aria-label="remove"></button>';

                const removeClickHandler = evt => {
                    onRemove(data.id, {}, baseElement);
                };

                on('click', removeClickHandler, markupElementRemoveButton.childNodes[0]);
                markupElementItem.childNodes[0].appendChild(markupElementRemoveButton.childNodes[0]);
                return markupElementItem.childNodes[0];
            },
            'fallback': `<div class="enquiry-fallback">${translate('pool-enquiry.no-added-enquiries')}</div>`
        }
    }
});

import * as renderInputRow from './scripts/render-input-row';
renderInputRow.init();

import * as experienceSlider from './scripts/experience-slider';
experienceSlider.init();

import * as affixNav from './scripts/affix-nav';
affixNav.init();

import Collapse from 'bootstrap.native/dist/components/collapse-native.esm';
onFind('[data-toggle="collapse"]',function (element) {
    new Collapse(element);
});

import Dropdown from 'bootstrap.native/dist/components/dropdown-native.esm';
onFind('[data-toggle="dropdown"]',function (element) {
    new Dropdown(element);
});

import * as fetchModal from './scripts/fetch-modal';
fetchModal.init();

import * as filterUploadTest from './scripts/fileUpload';
filterUploadTest.init();

import * as filterDropdown from './scripts/filterDropdown';
filterDropdown.init();

import * as playVideo from './scripts/playVideo';
playVideo.init();

import * as requiredSelect from './scripts/required-select';
requiredSelect.init();

import * as lightbox from './scripts/elements-packages/lightbox';
lightbox.init();
