"use strict";
import * as wishlistbundle from "@elements/wishlist";
import {findAllIn, find, findIn, findAll, on} from "@elements/dom-utils";
import {onFind } from "@elements/init-modules-in-scope";

let wishlist = null;
let clearNotification = 0;
if (window['_wishlist']) {
    wishlist = wishlistbundle.createWishlist({
        ...window['_wishlist'],
        notificationOptions: {
            $container: find('.js-wishlist__notification'),
        }
    })
}


export function init() {
    let myWishlists = [];

    if (window['_wishlists']) {
        for(let i = 0; i < window['_wishlists'].length; i++){
            myWishlists.push({[window['_wishlists'][i].wishlistName] : wishlistbundle.createWishlist({
                    ...window['_wishlists'][i],
                    notificationOptions: {
                        $container: find('.js-wishlist__notification'),
                    }
                }, {
                    item:  window['_wishlists'][i].wishlistId ? '.js-wishlist-' + window['_wishlists'][i].wishlistId + '__btn' : '.js-wishlist__btn',
                    itemId: 'wishlist-id',
                    wishlistId: 'wishlist-list',
                    itemActiveClass: 'is-active'
                })});
            

            myWishlists[i][window['_wishlists'][i].wishlistName].init();
        }
    }else{
        console.warn('No wishlist data found');
    }

    if(wishlist){
        wishlist.init();
    }

    
    onFind('.js-wishlist__container', function (baseElement) {
        let container = baseElement,
            button = findIn('.js-wishlist__remove', container),
            wishlistId = container.getAttribute('data-wishlist-list-name') || false,
            id = container.getAttribute('data-wishlist-id');

        let buttonClickHandler = (evt, button) => {
            evt.preventDefault();
            let params = {};
            if(wishlistId) {
                params = {
                    wishlistId: wishlistId
                }
            }

            myWishlists.map(function(wishlist) {
                if(wishlist.hasOwnProperty(wishlistId)) {

                    let _request =  wishlist[wishlistId].remove(id, params);

                    _request.then(function (res) {
                        container.remove();
                        if (wishlistId === 'press-pictures' && !wishlist[wishlistId].getState().length) {
                            /* Reload the page after the last press image is removed to render the correct whislist state (e.g. enable download all button ) */
                            location.reload();
                        }
                    })
                }
            })

        };

        on('click', (evt) => buttonClickHandler(evt, button), button);
    });



    const renderWishlistHandler = (evt, params) => {
        if(params.list === "wishlist-list"){
            // update counter
            let wishlistItemCounter = findAll('.js-wishlist__items-count');
            if(params.activeIds && wishlistItemCounter){
                wishlistItemCounter.map(function(counter) {
                    const count = params.counter || params.activeIds.length;
                    if(count <= 0) {
                        counter.innerHTML= count;
                        counter.setAttribute('hidden', 'hidden');
                    }else {
                        counter.innerHTML= count;
                        counter.removeAttribute('hidden');
                    }
                })

            }
        }
    };

    on('renderd.wishlist', (evt) => renderWishlistHandler('params', evt.detail) , document);


    const addedWishlistHandler = (evt, params) => {


        onFind('[data-wishlist-id]', function(baseElement){
            toggleEventTrackingAttributes(baseElement, false);
        });

        if(params.list === "wishlist-list"){
            clearNotification++;

            // hide notification after some seconds
            setTimeout(function(){
                if(clearNotification === 1){
                    find('.js-alert-notification').innerHTML = '';
                }

                clearNotification--;

            }, 5000);
        }

    };
    on('added.wishlist', (evt) => addedWishlistHandler('params', evt.detail) , document);

    const removeWishlistHandler = (evt, params) => {
        onFind('[data-wishlist-id]', function(baseElement){
            toggleEventTrackingAttributes(baseElement, true);
        });

        if(params.list === "wishlist-list"){
            clearNotification++;

            // hide notification after some seconds
            setTimeout(function(){
                if(clearNotification === 1){
                    find('.js-alert-notification').innerHTML = '';
                }

                clearNotification--;

            }, 3500);
        }

    };
    on('removed.wishlist', (evt) => removeWishlistHandler('params', evt.detail) , document);
}

function toggleEventTrackingAttributes(baseElement, enable){
    const attributeToggle = baseElement.getAttribute('data-event-tracking-action-toggle');
    if (attributeToggle){
        if (baseElement.classList.contains('is-active')){
            baseElement.setAttribute('data-event-tracking-action', baseElement.getAttribute('data-event-tracking-action')+attributeToggle);
        } else {
            baseElement.setAttribute('data-event-tracking-action', baseElement.getAttribute('data-event-tracking-action').replace(attributeToggle, ''));
        }
    }
}