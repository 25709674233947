"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, findAllIn, findIn, hasClass, closest} from "@elements/dom-utils";

export function init() {
    onFind('.js-required-select', function (container) {
        let select = findIn('.js-required-select__select', container);
        let inputs = findAllIn('.js-required-select__input', container);

        on('change', () => {
            if(hasClass('js-required-select__true', select.options[select.options.selectedIndex])){
                inputs.required = true;
                inputs.map(input => {
                    input.required = true;
                    findIn('span[title="Pflichtfeld"]', closest('.form-group', input)).style.display = 'inline';
                });
            }else{
                inputs.required = false;
                inputs.map(input => {
                    input.required = false;
                    findIn('span[title="Pflichtfeld"]', closest('.form-group', input)).style.display = 'none';
                });
            }
        }, select)
    });
}
