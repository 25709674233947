"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {hasClass, removeClass, findIn, on, closest, findAllIn} from "@elements/dom-utils";

export function init() {

    onFind('.js-filter-dropdown', function (dropdown) {
        let menu = findIn('.js-filter-dropdown__menu', dropdown);
        on('click', function(evt) {
            evt.stopImmediatePropagation();
        }, menu)

        let parents = findAllIn('.js-filter-dropdown__parent', menu);
        let lastParent = parents[parents.length - 1];
        let children = findAllIn('.js-filter-dropdown__child', menu);
        let lastChild = children[children.length - 1];

        parents.map((parent, i) => {
            on('click', (evt) => {
                let parentKey = parent.getAttribute('data-item');
                let nextParentKey = 0;
                if(lastParent === parent){
                    nextParentKey = lastChild.getAttribute('data-item') + 1;
                }else{
                    nextParentKey = parents[i + 1].getAttribute('data-item');
                }

                children.map(child => {
                    if(parseInt(child.getAttribute('data-item')) > parentKey && parseInt(child.getAttribute('data-item')) < nextParentKey){
                        if(findIn('input', parent).checked){
                            findIn('input', child).checked = true;
                        }else{
                            findIn('input', child).checked = false;
                        }
                    }
                });
            }, parent);
        })
    });
}