"use strict";

import {initAjaxFormMap as initAjaxFormMapGoogle} from "@elements/ajax-form-map/ajax-form-google";
import {findAll, findIn, on} from '@elements/dom-utils';
import {initInScope} from "@elements/init-modules-in-scope";
import fetch from "@elements/fetch"
import {onEnterViewPort} from "@elements/viewport-utils";

const isMobile = () => matchMedia('(max-width: 767px)').matches;

export function init () {
    function getGooglePoiStyles(){
        return {
            default: {
                default: {
                    url: '/static/img/pois/hotel.svg',
                    size: new google.maps.Size(72, 83),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(27, 37)
                },
                active: {
                    url: '/static/img/pois/hotel.svg',
                    size: new google.maps.Size(72, 83),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(27, 37)
                }
            }
        };
    }

    function getGoogleInfoBoxOptions(){
        let width = isMobile() ? "300px" : "600px";
        let offsetX = isMobile() ? -150 : -158;
        let offsetY = isMobile() ? -22 : -50;

        return {
            pixelOffset: new google.maps.Size(offsetX, offsetY),
            boxStyle: {width: width, padding: "0"},
            boxClass: "info-box",
            closeBoxURL: "/static/img/close.svg"
        };
    }

    function onActivateMarker(marker, api) {
        if(marker.detailInfoBoxUrl) {
            let infoBoxPromise = api.showInfoBoxByMarker(
                marker,
                ` <div class="loading-spinner" aria-label="loading...">
                    <div class="loading-spinner__item loading-spinner__item--1"></div>
                    <div class="loading-spinner__item loading-spinner__item--2"></div>
                    <div class="loading-spinner__item loading-spinner__item--3"></div>
                </div>`
            );

            let contentPromise = fetch(marker.detailInfoBoxUrl, {
                method: 'GET',
                headers: {"content-type": "application/json"}
            }).then((res) => {
                return res.json()
            });


            Promise.all([infoBoxPromise, contentPromise]).then(([infoBox, response]) => {
                infoBox.setContent(response.html);

                let t = setTimeout(function () {
                    initInScope(infoBox.div_);

                    clearTimeout(t);
                }, 100);
            });
        }
    }

    function onDeactivateMarker(marker, api) {
        api.closeInfoBoxByMarker(marker);
    }

    findAll('.js-ajax-form-map').map(googleMap => {
        let lat = +googleMap.getAttribute('data-google-map-initial-lat') || 47.2183939;
        let lng = +googleMap.getAttribute('data-google-map-initial-lng') || 14.306775;

        let selectors = {
            result: '.js-ajax-form-map__result',
            loading: '.js-ajax-form-map__loading',
            notifications: '.js-ajax-form-map__notifications',
            form: '.js-ajax-form-map__form',
            additionalForm: '.js-ajax-form-map__additional-form',
            errorArea: '.js-ajax-form-map__error-area',
            retry: '.js-ajax-form-map__retry',
            link: '.js-ajax-form-map__link',
            reset: '.js-ajax-form-map__reset',
        };


        let map = initAjaxFormMapGoogle(googleMap, {
            element: findIn('.js-ajax-form-map__map__canvas', googleMap),
            poiStyles: () => getGooglePoiStyles(),
            infoBoxOptions: () => getGoogleInfoBoxOptions(),
            clustering: false,
            onActivateMarker: (marker, api) => onActivateMarker(marker, api),
            onDeactivateMarker: (marker, api) => onDeactivateMarker( marker, api),
            mapOptions: {
                center: {lat: lat, lng: lng},
                zoom: 10,
                scrollwheel: true,
                mapTypeControl: false,
                streetViewControl: false,
                style: [
                    {
                        "featureType": "administrative.locality",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.natural",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    }
                ]
            }
        }, selectors);

        onEnterViewPort(findIn('.js-ajax-form-map__map__canvas', googleMap), function () {
            map.mapObj.then((mapObj) => mapObj.centerMap())
        }, {
            offset: window.innerHeight * 1.5
        });

        on('reset.ajax-form', (evt) => {
            map.formApi.submit();
        }, googleMap);
    });
}
