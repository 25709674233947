/** @jsx h */

import {h, render} from "preact";
import {onFind} from "@elements/init-modules-in-scope";
import {findIn} from "@elements/dom-utils";
import {useState} from "preact/hooks";
import {translate} from '@elements/translations';
import escapeHtml from 'escape-html';

const selectors = {
    base: '.js-render-input-row',
    result: '.js-render-input-row__result'
};

export function init() {
    onFind(selectors.base, function (baseElement) {

        let result = findIn(selectors.result, baseElement);
        let itemName = baseElement.getAttribute('data-render-input-row-name');

        //prefill first row
        let rowsObj = [0];

        render(<RenderInputList
                name={itemName}
                defaultRows={rowsObj}
            />,
            result);
    });
}

function RenderInputList({
     name = "",
     defaultRows = []
 }) {
    const [rows, setRows] = useState(defaultRows);

    const deleteAction = (id) => {
        let tempRows = [...rows];
        let filteredRows = tempRows.filter(function (value) {
            return value != id;
        });
        setRows([...Array(filteredRows.length).keys()]);
    };

    const addAction = (id) => {
        let tempRows = [...rows];
        tempRows.push(id);
        setRows(tempRows);
    };

    return (
        <div>
            {rows.map((id, key) => (
                <div className="row" key={key}>
                    <div className="col-md-3">
                        <FormInput
                            id={`${name}-${id}-year`}
                            name={`${name}[${id}]['year']`}
                            label={translate('cinestyria.Year')}
                            defaultVal=""
                        />
                    </div>
                    <div className="col-md-9">
                        <FormInput
                            id={`${name}-${id}-description`}
                            name={`${name}[${id}]['description']`}
                            label={translate('cinestyria.Description')}
                            defaultVal=""
                        />
                        <span className="references-upload-block__delete-row icon icon-recycle-bin" onClick={(evt) => {
                            evt.preventDefault();
                            deleteAction(id);
                        }}/>
                    </div>
                </div>
            ))}

            <div className="d-flex justify-content-center mb-4">
                <button className="btn btn-outline-white btn-icon-text"
                        onClick={(evt) => {
                            evt.preventDefault();
                            let newIndex = rows.length > 0 ? rows[rows.length - 1] + 1 : 0;
                            addAction(newIndex);
                        }}>{translate('cinestyria.add-row')}<span
                    className="btn-icon-text__icon btn-icon-text__icon--right icon icon-plus"/></button>
            </div>
        </div>

    )
};

function FormInput({
    id = "",
    name = "",
    label = "",
    defaultVal = ""
 }) {
    const [value, setValue] = useState(defaultVal);

    const changeHandler = (evt) => {
        let newInput = escapeHtml(evt.target.value);
        setValue(newInput );
    };

    return (
        <div className={`form-group ${value.length > 0 ? 'has-value' : ''}`}>
            <div className="position-relative">
                <label className="form-control-label floating-label" htmlFor={id}>{label}</label>
                <input type="text" id={id} name={name}
                       className="form-control" onInput={(evt) => {
                           changeHandler(evt);
                }}/>
            </div>
        </div>
    )
};